@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
figcaption {
  color: gray;
  font-style: italic;
  padding: 2px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background: #f8fafc;
  height: 100vh;
  line-height: 1.6;
  font-weight: 300;
}

.navBar {
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
}

.navBar ul {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: center;
}

.navBar li {
  padding: 1rem;
  margin: 0 1px;
  /* background-color: #333; */
}


.navBar ul li a {
  text-decoration: none;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: #f4f4f4;
}

.navBar ul li a:hover {
  color: #58990e;
  border-bottom: 3px solid rgb(49, 214, 27);
}
.App {
  text-align: center;
  /*display: flex;
  flex-direction: column;*/
  justify-content: center;

  align-items: center;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100vh;
}

button {
  margin: 8px;
  padding: 2px;
}

input {
  margin: 3px;
}

table, th, td {
  border: 1px solid black;
  padding: 0.5rem;
}

table {
  display: block;
  overflow-y: auto;

  width: 90%;
  height: 400px;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}

ul li {
  text-align: left;
  padding: 0.5rem;
  margin-left: 3rem;
}

a {
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

a:link {
  color: #488f0e;
}

a:visited {
  color: #549b19;
}

